enum MobileOS {
  IOS,
  Android,
  Unknown,
}

enum Langs {
  CN,
  EN,
}

type Info = {
  iosDeepLink: string;
  androidDeepLink?: string;
  pcURL: string;
};

function openApp(link: string) {
  const deepLink = link + location.search;
  const now = Date.now();
  setTimeout(function () {
    if (Date.now() - now > 100) return;
    console.log('not support');
  }, 25);
  location.href = deepLink;
}

function getMobileOS() {
  const ua = navigator.userAgent || navigator.vendor;
  if (/android/i.test(ua)) return MobileOS.Android;
  if (/iPad|iPhone|iPod/.test(ua) && !window.MSStream) return MobileOS.IOS;
  return MobileOS.Unknown;
}

function getLang() {
  const { documentElement } = document;
  const lang = documentElement.getAttribute("lang");
  return lang == "en" ? Langs.EN : Langs.CN;
}

function getInfo(): Info {
  const lang = getLang();
  if (lang == Langs.EN) {
    return {
      androidDeepLink: "carego-international://protector/invitation",
      iosDeepLink: "carego://invitation",
      pcURL: "https://en.alphahom.com/",
    };
  }
  return {
    androidDeepLink: "carego-domestic://protector/invitation",
    iosDeepLink: "caregodomestic://invitation",
    pcURL: "https://cn.alphahom.com/",
  };
}

function init() {
  const info = getInfo();
  const button = document.getElementById("download") as HTMLAnchorElement;
  const mobileOS = getMobileOS();
  if (mobileOS == MobileOS.IOS) {
    const downloadURL = button.dataset.iosUrl;
    button.href = downloadURL;
    openApp(info.iosDeepLink);
  } else if (mobileOS == MobileOS.Android) {
    const downloadURL = button.dataset.androidUrl;
    button.href = downloadURL;
    openApp(info.androidDeepLink);
  } else {
    location.href = info.pcURL;
  }
}

init();
